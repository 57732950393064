.number {
  max-height: 50px;
  max-width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: var(--green-4);
}

.description {
  max-width: 400px;
}

.arrow {
  font-size: 24px;
}

.next,
.back {
  align-items: flex-start;
}

.next {
  .arrow {
    transform: rotate(270deg);
    fill: var(--button-text-color);
  }
}

.back {
  padding-right: 15px;

  &:not(:disabled):hover {
    box-shadow: 0 0 4px var(--button-color-secondary);
  }

  .arrow {
    transform: rotate(90deg);
    fill: var(--button-text-color-secondary);
  }
}

.video {
  height: 378px;
  width: 100%;
  border-radius: 10px;
}

.download {
  stroke: var(--font-color-primary);
  font-size: 24px;
}