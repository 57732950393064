.content {
  margin-top: 90px;
}
.center {
  white-space: pre-wrap;
  text-align: center;
}
.label {
  margin-bottom: 5px;
}
