.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 512px) {
    height: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: 486px;
  width: 100%;
  flex: 1;
  margin: 0 auto;
}

.body {
  flex: 1;
}

.logo + .title {
  margin-top: 82px;
  @media (max-width: 512px) {
    margin-top: 3vh;
  }
}


.title + .body {
  margin-top: 42px;
}

.body + .footer {
  margin-top: 32px;
}

.footer {
  align-self: center;
}

.hint {
  align-self: center;
}
