@import './reset';
@import './variables/global';

body {
  background: var(--layout-bg-color);
  color: var(--font-color-primary);
  font: var(--font-2);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
}

ol {
  list-style-position: inside;
}

.full-container {
  width: 100%;
  height: 100vh;
}

.hidden-overflow {
  overflow: hidden;
}

.close-modal-state {
  transform: scale(0);
}

.framePaycos {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  border: none;
}

.fix {
  margin-bottom: 30px;
  margin-top: -10px;
}