.group {
  max-width: 332px;
  --radio-size: 32px;

  .item {
    background: var(--color-fill-3);
    padding: 20px;
    border-radius: var(--container-border-radius);
    margin: 1px;
  }

  .item-active {
    background: var(--color-fill-11);
    border: 1px solid var(--border-color-4);
    margin: 0;
  }

  .checkbox-content {
    flex: 1;
  }

  .button {
    padding: 0;
    height: auto;
  }

  .icon {
    font-size: 20px;
    fill: var(--font-color-secondary);
  }
}
