.collapse {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}

.header {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 8px;
}

.text {
  color: var(--font-color-primary);
  font: var(--font-1);
}

.arrow {
  fill: var(--font-color-primary);
  transition: all ease 0.3s;
  display: flex;
}

.content {
  overflow: hidden;
  transition: height ease 0.3s;
}

.collapse-open {
  .header {
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.collapse-closed {
  .header {
    .arrow {
      transform: rotate(0deg);
    }
  }
}
