.hint {
  display: flex;
  gap: 4px;
}

.input, #t2, #t3, #t4 {
  width: min(15vw, 80px);
  height: min(25vh, 110px);
  --input-font-size: 40px;
  padding: 16px;
}

@media (max-width: 512px) {
  .input, #t2, #t3, #t4 {
    padding: 2vw;
    height: 100px;
    --input-font-size: 40px;
    width: min(15vw, 80px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.input-border {
  background-color: var(--input-fill-color);
  border-radius: var(--input-border-radius, 8px);
  display: inline-block;
  font-size: 17px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.wrapper {
  width: 100%;
}

.mail {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.envelope {
  height: 24px;
  width: 24px;
}

.buttons {
  width: 100%;
}