.step {
  &:not(:first-child) {
    padding-top: 20px;
  }

  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color-7);
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.number {
  display: flex;
  gap: 8px;
}

.number-tag {
  text-transform: uppercase;
}

.description {
  list-style-position: inside;
  color: var(--font-color-tertiary);
  white-space: pre-line;

  a {
    text-decoration: underline;
  }
}

.image {
  max-width: 396px;
  height: 241px;
  flex: 1 0 396px;

  img {
    border-radius: 15px;
    object-fit: contain !important;
  }
}

.image-size-large {
  height: 360px;
}

.image-size-extra-large {
  height: 448px
}

.images-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .image {
    flex: auto;
  }
}
